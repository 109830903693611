import warsawBackgroundVideo from "assets/videos/background-video-placeholder-warsaw-v2.mp4";
import backgroundVideo from "assets/videos/background-video-placeholder.mp4";
import warsawBackgroundMobileVideo from "assets/videos/mobile-background-video-warsaw-v2.mp4";
import backgroundMobileVideo from "assets/videos/mobile-background-video.mp4";
import priceBanner20 from "assets/images/priceBanners/priceBanner20.png";
import priceBanner25 from "assets/images/priceBanners/priceBanner25-v5.png";
import priceBanner30 from "assets/images/priceBanners/priceBanner30.png";
import warsawBanner from "assets/images/banner-warsaw-v7.png";
import katowiceBanner from "assets/images/katowice-banner-v4.png";
import gdanskBanner from "assets/images/christmasBanners/christmas-banner-v2.png";
import wroclawBanner from "assets/images/christmasBanners/christmas-banner-v2.png";
import i18n from "i18n";

// siteUrl should be '/key', where key is the key of the location in the locations object
// this is because MainPage bases on the location param to render the correct location

export const getLocations = () => {
  return {
    wroclaw: {
      title: "WROCŁAW",

      // site data
      bookingUrl: {
        eng: "https://xl.plan4u.pl/pixel/xl/bookItNEW_EN.html",
        pl: "https://xl.plan4u.pl/pixel/xl/bookItNEW_PL.html",
      },
      backgroundVideo: backgroundVideo,
      backgroundMobileVideo: backgroundMobileVideo,

      vimeoBackgroundVideoURL:
        "https://player.vimeo.com/video/772339493?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",
      vimeoMobileBackgroundVideoURL:
        "https://player.vimeo.com/video/772344269?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",

      plan4uApiUrl: "https://xl.plan4u.pl/pixel/xl/api/v1",
      siteUrl: "/wroclaw",
      siteTitle: "Pixel XL Wroclaw",
      arenaWithCredits: false,
      googleMapsOptions: {
        latitude: 51.10697301550853,
        longitude: 17.03222937059691,
        zoom: 15,
      },
      aboutUsImages: [
        "/images/wroclaw/about-us/about-picture-1.jpg",
        "/images/wroclaw/about-us/about-picture-2.jpg",
        "/images/wroclaw/about-us/about-picture-3.jpg",
        "/images/wroclaw/about-us/about-picture-4.v2.jpg",
        "/images/wroclaw/about-us/about-picture-5.jpg",
        "/images/wroclaw/about-us/about-picture-6.jpg",
      ],
      aboutUsMobileImages: [
        "/images/wroclaw/about-us/mobile-about-picture-1.jpg",
        "/images/wroclaw/about-us/mobile-about-picture-2.jpg",
        "/images/wroclaw/about-us/mobile-about-picture-3.jpg",
        "/images/wroclaw/about-us/mobile-about-picture-4.v2.jpg",
        "/images/wroclaw/about-us/mobile-about-picture-5.jpg",
        "/images/wroclaw/about-us/mobile-about-picture-6.jpg",
      ],
      bookingPageBanner: wroclawBanner,
      priceBanner: priceBanner20,
      cooperationSectionVisibility: true,
      integrationOfferVisibility: true,
      birthdayOfferVisibility: true,
      schoolOfferVisibility: true,
      address: "Świdnicka 12, 50-068 Wrocław",
      email: "wroclaw@pixel-xl.com",
      voucherPageUrl: "https://xl.plan4u.pl/pixel/xl/getVoucher.html",
    },
    katowice: {
      title: "KATOWICE",

      // site data
      siteUrl: "/katowice",
      bookingUrl: {
        eng: "https://xl.plan4u.pl/pixel/katowice/bookItNEW_EN.html",
        pl: "https://xl.plan4u.pl/pixel/katowice/bookItNEW_PL.html",
      },
      backgroundVideo: backgroundVideo,
      backgroundMobileVideo: backgroundMobileVideo,

      vimeoBackgroundVideoURL:
        "https://player.vimeo.com/video/772339493?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",
      vimeoMobileBackgroundVideoURL:
        "https://player.vimeo.com/video/772344269?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",

      plan4uApiUrl: "https://xl.plan4u.pl/pixel/katowice/api/v1",
      siteTitle: "Pixel XL Katowice",
      arenaWithCredits: true,
      googleMapsOptions: {
        latitude: 50.262872,
        longitude: 19.020316,
        zoom: 16,
      },
      aboutUsImages: [
        "/images/wroclaw/about-us/about-picture-1.jpg",
        "/images/katowice/about-us/about-picture-2.v2.jpg",
        "/images/wroclaw/about-us/about-picture-3.jpg",
        "/images/katowice/about-us/about-picture-4.png",
        "/images/wroclaw/about-us/about-picture-5.jpg",
        "/images/katowice/about-us/about-picture-6.png",
      ],
      aboutUsMobileImages: [
        "/images/wroclaw/about-us/mobile-about-picture-1.jpg",
        "/images/katowice/about-us/mobile-about-picture-2.v2.jpg",
        "/images/wroclaw/about-us/mobile-about-picture-3.jpg",
        "/images/katowice/about-us/mobile-about-picture-4.png",
        "/images/wroclaw/about-us/mobile-about-picture-5.jpg",
        "/images/katowice/about-us/mobile-about-picture-6.png",
      ],
      bookingPageBanner: katowiceBanner,
      priceBanner: priceBanner30,
      cooperationSectionVisibility: true,
      integrationOfferVisibility: true,
      birthdayOfferVisibility: true,
      schoolOfferVisibility: true,
      address: "Sokolska 31, 40-086 Katowice",
      email: "katowice@pixel-xl.com",
      voucherPageUrl: "https://xl.plan4u.pl/pixel/katowice/getVoucher.html",
      // classesCatalog: "/documents/katowice/classes-v3.pdf",
      // classesStatue: "/documents/katowice/classes-statue.pdf",
    },
    slask: {
      title: "KATOWICE",
      // site data
      siteUrl: "/slask",
      bookingUrl: {
        eng: "https://xl.plan4u.pl/pixel/katowice/bookItNEW_EN.html",
        pl: "https://xl.plan4u.pl/pixel/katowice/bookItNEW_PL.html",
      },
      backgroundVideo: backgroundVideo,
      backgroundMobileVideo: backgroundMobileVideo,

      vimeoBackgroundVideoURL:
        "https://player.vimeo.com/video/772339493?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",
      vimeoMobileBackgroundVideoURL:
        "https://player.vimeo.com/video/772344269?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",

      plan4uApiUrl: "https://xl.plan4u.pl/pixel/katowice/api/v1",
      siteTitle: "Pixel XL Slask",
      arenaWithCredits: true,
      googleMapsOptions: {
        latitude: 50.262872,
        longitude: 19.020316,
        zoom: 16,
      },
      aboutUsImages: [
        "/images/wroclaw/about-us/about-picture-1.jpg",
        "/images/katowice/about-us/about-picture-2.v2.jpg",
        "/images/wroclaw/about-us/about-picture-3.jpg",
        "/images/katowice/about-us/about-picture-4.png",
        "/images/wroclaw/about-us/about-picture-5.jpg",
        "/images/katowice/about-us/about-picture-6.png",
      ],
      aboutUsMobileImages: [
        "/images/wroclaw/about-us/mobile-about-picture-1.jpg",
        "/images/katowice/about-us/mobile-about-picture-2.v2.jpg",
        "/images/wroclaw/about-us/mobile-about-picture-3.jpg",
        "/images/katowice/about-us/mobile-about-picture-4.png",
        "/images/wroclaw/about-us/mobile-about-picture-5.jpg",
        "/images/katowice/about-us/mobile-about-picture-6.png",
      ],
      bookingPageBanner: katowiceBanner,
      priceBanner: priceBanner30,
      cooperationSectionVisibility: true,
      integrationOfferVisibility: true,
      birthdayOfferVisibility: true,
      schoolOfferVisibility: true,
      address: "Sokolska 31, 40-086 Katowice",
      email: "katowice@pixel-xl.com",
      voucherPageUrl: "https://xl.plan4u.pl/pixel/katowice/getVoucher.html",
      // classesCatalog: "/documents/katowice/classes-v3.pdf",
      // classesStatue: "/documents/katowice/classes-statue.pdf",
    },
    warszawa: {
      title: "WARSZAWA",
      label: "Pixel XL BOX",

      // site data
      siteUrl: "/warszawa",
      bookingUrl: {
        eng: "https://xl.plan4u.pl/pixel/warszawa/bookItNEW_EN.html",
        pl: "https://xl.plan4u.pl/pixel/warszawa/bookItNEW_PL.html",
      },

      backgroundVideo: warsawBackgroundVideo,
      backgroundMobileVideo: warsawBackgroundMobileVideo,

      vimeoBackgroundVideoURL:
        "https://player.vimeo.com/video/1020127654?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",
      vimeoMobileBackgroundVideoURL:
        "https://player.vimeo.com/video/1020128108?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",

      plan4uApiUrl: "https://xl.plan4u.pl/pixel/warszawa/api/v1",
      siteTitle: "Pixel XL Warszawa",
      arenaWithCredits: true,
      googleMapsOptions: {
        latitude: 52.25625,
        longitude: 20.983733,
        zoom: 16,
      },
      aboutUsImages: [
        "/images/warszawa/about-us/about-picture-1.v2.jpg",
        "/images/warszawa/about-us/about-picture-2.v2.jpg",
        "/images/warszawa/about-us/about-picture-3.v2.jpg",
        "/images/warszawa/about-us/about-picture-4.v2.jpg",
        "/images/warszawa/about-us/about-picture-5.v2.jpg",
        "/images/warszawa/about-us/about-picture-6.v2.jpg",
      ],
      aboutUsMobileImages: [
        "/images/warszawa/about-us/about-picture-1.v2.jpg",
        "/images/warszawa/about-us/about-picture-2.v2.jpg",
        "/images/warszawa/about-us/about-picture-3.v2.jpg",
        "/images/warszawa/about-us/about-picture-4.v2.jpg",
        "/images/warszawa/about-us/about-picture-5.v2.jpg",
        "/images/warszawa/about-us/about-picture-6.v2.jpg",
      ],
      bookingPageBanner: warsawBanner,
      priceBanner: priceBanner25,
      cooperationSectionVisibility: true,
      integrationOfferVisibility: false,
      birthdayOfferVisibility: false,
      schoolOfferVisibility: true,

      address: "al. Jana Pawła II 82, 00-175 Warszawa",
      email: "warszawa@pixel-xl.com",
      voucherPageUrl: "https://xl.plan4u.pl/pixel/warszawa/getVoucher.html",
    },
    gdansk: {
      title: "TRÓJMIASTO",
      label: i18n.t("locationsPage.novelty"),

      // site data
      bookingUrl: {
        eng: "https://xl.plan4u.pl/pixel/gdansk/bookItNEW_EN.html",
        pl: "https://xl.plan4u.pl/pixel/gdansk/bookItNEW_PL.html",
      },
      backgroundVideo: backgroundVideo,
      backgroundMobileVideo: backgroundMobileVideo,

      vimeoBackgroundVideoURL:
        "https://player.vimeo.com/video/772339493?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",
      vimeoMobileBackgroundVideoURL:
        "https://player.vimeo.com/video/772344269?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",

      plan4uApiUrl: "https://xl.plan4u.pl/pixel/gdansk/api/v1",
      siteUrl: "/gdansk",
      siteTitle: "Pixel XL Gdańsk",
      arenaWithCredits: true,
      googleMapsOptions: {
        latitude: 54.40439190823928,
        longitude: 18.57099491269834,
        zoom: 15,
      },
      aboutUsImages: [
        "/images/wroclaw/about-us/about-picture-1.jpg",
        "/images/gdansk/about-us/about-picture-1.png",
        "/images/wroclaw/about-us/about-picture-3.jpg",
        "/images/gdansk/about-us/about-picture-2.png",
        "/images/wroclaw/about-us/about-picture-5.jpg",
        "/images/gdansk/about-us/about-picture-3.png",
      ],
      aboutUsMobileImages: [
        "/images/wroclaw/about-us/mobile-about-picture-1.jpg",
        "/images/gdansk/about-us/mobile-about-picture-1.png",
        "/images/wroclaw/about-us/mobile-about-picture-3.jpg",
        "/images/gdansk/about-us/mobile-about-picture-2.png",
        "/images/wroclaw/about-us/mobile-about-picture-5.jpg",
        "/images/gdansk/about-us/mobile-about-picture-3.png",
      ],
      bookingPageBanner: gdanskBanner,
      priceBanner: priceBanner30,
      cooperationSectionVisibility: true,
      integrationOfferVisibility: true,
      birthdayOfferVisibility: true,
      schoolOfferVisibility: true,
      address: "Al. Grunwaldzka 472f (Prime B), 80-309 Gdańsk",
      email: "gdansk@pixel-xl.com",
      voucherPageUrl: "https://xl.plan4u.pl/pixel/gdansk/getVoucher.html",
    },
    poznan: {
      title: "POZNAŃ",
      label: "2025",
      siteUrl: "/poznan",
    },
    krakow: {
      title: "KRAKÓW",
      label: "2025",
      siteUrl: "/krakow",
    },
  };
};
